import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import SignUpModal from './SignUpModal'

const propTypes = {
  copy: PropTypes.string
}

const defaultProps = {
  copy: 'Try it for free'
}

function TryItForFree ({
  copy
}) {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <SignUpModal showModal={showModal} setShowModal={setShowModal} />
      <button className="relative tracking-[0.5px] leading-[100%] font-semibold font-sora bg-sandybrown rounded-3xs cursor-pointer text-xl border-sandybrown border border-solid px-8 py-[18px]" onClick={() => setShowModal(true)}>
        {
          copy
        }
      </button>
    </>
  )
}

TryItForFree.propTypes = exact(propTypes)
TryItForFree.defaultProps = defaultProps

export default memo(TryItForFree)