import React, { memo } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import useViewPort from '../utils/viewPort'
import Flickity from 'react-flickity-component'

const propTypes = {}

const defaultProps = {
  onlyMobile: false,
  overwriteDefaultFlickityOptions: {}
}

function Carousel({ 
  onlyMobile,
  overwriteDefaultFlickityOptions,
  children 
}) {
  const width = useViewPort()
  const flickityOptions = {
    initialIndex: 2,
    freeScroll: true,
    autoPlay: 2500,
    pageDots: false,
    contain: true,
    wrapAround: true,
    prevNextButtons: false
  }

  if (width > 1023 && !onlyMobile) {
    return children
  } else {
    return (
      <Flickity
        className={'carousel w-full cursor-pointer'} // default ''
        elementType={'div'} // default 'div'
        options={{...flickityOptions, ...overwriteDefaultFlickityOptions}} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
      >
        {children}
      </Flickity>
    )
  }
}

Carousel.propTypes = exact(propTypes)
Carousel.defaultProps = defaultProps

export default memo(Carousel)