import { round } from "lodash"

export function calculatePrice(keywords) {
  // Calculate the total price a user will have to pay for the service if the each keyword costs $0.12 per keyword but after the 999 keywords, the price drops to $0.10 per keyword for the rest of the keywords
  // Example: 1000 keywords will cost $119.98 because the first 999 keywords cost 0.12 per keyword and the rest of the keywords cost 0.10 per keyword
  // The full price then would be 119.88 + 150.1 = 269.98
  let total = 0
  if (keywords <= 999) {
    total = keywords * 0.21
  }
  else {
    total = 999 * 0.21 + (keywords - 999) * 0.11
  }
  return round(total, 2)
}