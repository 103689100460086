import React, { memo } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { NewRegistrationForm } from 'forms'
import { LoginLinks } from 'components'
import Application from '../Application'

const propTypes = {
  resource: PropTypes.object,
  submitUrl: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired
}

const defaultProps = {}

function NewRegistration ({
  resource,
  submitUrl,
  isRecoverable,
  isConfirmable,
  isRegisterable,
  isLockable,
  authenticityToken
}) {
  return (
    <Application>
      <h2>Sign up</h2>
      <NewRegistrationForm user={resource} submitUrl={submitUrl} authenticityToken={authenticityToken} />
      <LoginLinks
        isRecoverable={isRecoverable}
        isConfirmable={isConfirmable}
        isRegisterable={isRegisterable}
        isLockable={isLockable}
      />
    </Application>
  )
}

NewRegistration.propTypes = exact(propTypes)
NewRegistration.defaultProps = defaultProps

export default memo(NewRegistration)