import React from 'react';

const bannerStyle = {
  backgroundColor: '#FFD700',
  textAlign: 'center',
  padding: '20px',
  fontSize: '24px',
  color: 'black',
};

const Banner = () => (
  <div className="banner-container" style={bannerStyle}>
    We are turning our attention to our core product in anticipation of our release. We apologize for the state of the website. We will clean that up as we get closer to launch.
  </div>
);

export default Banner;
