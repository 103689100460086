import React, { memo } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import MainMenu from './MainMenu'

const propTypes = {}

const defaultProps = {}

function HeroSection ({ children }) {
  return (
    <div className="bg-hero-section relative bg-coolgray-90 w-full flex flex-col items-start justify-start text-left text-[1rem] text-light-text font-heading">
      <MainMenu />
      { children }
    </div>
  )
}

HeroSection.propTypes = exact(propTypes)
HeroSection.defaultProps = defaultProps

export default memo(HeroSection)