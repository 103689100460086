import React, { memo } from 'react'
import Application from './Application'
import HeroSection from '../components/HeroSection'

const propTypes = {}

const defaultProps = {}

function Contact() {
  return (
    <Application>
      <HeroSection>
        <div className="w-full flex flex-col items-center justify-start pt-0 pb-[5rem] md:py-[5rem] px-[16px] md:px-0 lg:px-[5rem] box-border relative gap-[0.63rem] text-[2.63rem]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[1.38rem]">
            <div className='flex flex-col justify-center w-full items-center'>
              <div className="relative text-[3rem] tracking-[0.2px] font-semibold inline-block w-full lg:w-[40.69rem] mb-6">
                <p className='text-center'>
                  Contact Us
                </p>
              </div>
              <div className='flex w-full lg:w-1/2 xl:w-3/5 xl:max-w-[1220px] flex-col justify-center'>
                <form method='GET' action='/' id='kwwd-contact-us-form' className='bg-white w-full rounded-t-xl py-[5rem] px-[16px] md:px-[5rem] box-border'>
                  <div className='flex flex-col mb-4'>
                    <label className='text-coolgray-90 text-sm' htmlFor='name'>
                      Name
                    </label>
                    <input className='rounded-3xs mt-[0.5rem] p-[0.625rem] bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full bg-cool-gray-60 dark:border-gray-600 border-solid h-[3rem] box-border' type='text' id='name' placeholder='Your full name' />
                  </div>
                  <div className='flex flex-col mb-4'>
                    <label className='text-coolgray-90 text-sm' htmlFor='company_name'>
                      Company Name
                    </label>
                    <input className='rounded-3xs mt-[0.5rem] p-[0.625rem] bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full bg-cool-gray-60 dark:border-gray-600 border-solid h-[3rem] box-border' type='text' id='company_name' placeholder='Company name' />
                  </div>
                  <div className='flex flex-col mb-4'>
                    <label className='text-coolgray-90 text-sm' htmlFor='email'>
                      Email
                    </label>
                    <input className='rounded-3xs mt-[0.5rem] p-[0.625rem] bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full bg-cool-gray-60 dark:border-gray-600 border-solid h-[3rem] box-border' type='email' id='email' placeholder='example@example.com' />
                  </div>
                  <div className='flex flex-col'>
                    <label className='text-coolgray-90 text-sm' htmlFor='message'>
                      Message
                    </label>
                    <textarea className='rounded-3xs mt-[0.5rem] p-[0.625rem] bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full bg-cool-gray-60 dark:border-gray-600 border-solid h-[3rem] box-border' id='message' placeholder='Your message' />
                  </div>
                  <div className='flex flex-col justify-center items-center'>
                    <button className='rounded-3xs w-44 bg-sandybrown box-border h-[3.5rem] flex flex-row items-center justify-center p-[1rem] text-gray-300 border-[2px] border-solid border-sandybrown mt-4 text-base font-medium' type='submit'>
                      Send Message
                    </button>
                  </div>
                </form>
                <div className='flex bg-coolgray-10 box-border px-10 py-16 rounded-b-xl w-full flex-wrap gap-8'>
                  <div className='flex flex-wrap gap-8 basis-full md:justify-between w-full'>
                    <div className='flex flex-col'>
                      <h3 className='text-coolgray-90 text-xs'> SUPPORT </h3>
                      <a className='text-mediumslateblue-100 font-medium text-mid no-underline' href='mailto:support@keywordwatchdog.com'> support@keywordwatchdog.com </a>
                    </div>
                    <div className='flex flex-col'>
                      <h3 className='text-coolgray-90 text-xs'> NEW BUSINESS </h3>
                      <a className='text-mediumslateblue-100 font-medium text-mid no-underline' href='mailto:bizdev@keywordwatchdog.com'> bizdev@keywordwatchdog.com </a>
                    </div>
                  </div>
                  <div className='flex flex-wrap gap-8 basis-full md:justify-between w-full'>
                    <div className='flex flex-col'>
                      <h3 className='text-coolgray-90 text-xs'> ENTERPRISE </h3>
                      <a className='text-mediumslateblue-100 font-medium text-mid no-underline' href='mailto:enterprise@keywordwatchdog.com'> enterprise@keywordwatchdog.com </a>
                    </div>
                    <div className='flex flex-col'>
                      <h3 className='text-coolgray-90 text-xs'> MEDIA </h3>
                      <a className='text-mediumslateblue-100 font-medium text-mid no-underline' href='mailto:media@keywordwatchdog.com'> media@keywordwatchdog.com </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HeroSection>
    </Application>
  )
}

Contact.propTypes = propTypes
Contact.defaultProps = defaultProps

export default memo(Contact)