import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  white_variant: PropTypes.bool
}

const defaultProps = {
  white_variant: false
}

function KeywordWatchDogLogo ({
  white_variant
}) {
  return (
    <div className="flex flex-col items-start justify-start text-[1.5rem] font-sora">
      <div className="flex flex-row items-center justify-start gap-[0.25rem]">
        <a className='flex no-underline gap-4' href='/'>
          <div className="flex flex-row items-center justify-center">
            {
              white_variant ? 
              <img
                className="relative w-[3rem] h-[2rem]"
                alt=""
                src="/images/white-variant-logo.svg"
              /> :
              <img
                className="relative w-[3rem] h-[2rem]"
                alt=""
                src="/images/frame-14391.svg"
              />
            }
          </div>
          <div className="flex flex-col items-end justify-center">
            <div className="relative text-[1.25rem] tracking-[0.2px] leading-[5.5rem] text-light-text text-center mt-[-0.25rem] ">
              <span>Keyword</span>
              <b>Watchdog</b>
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

KeywordWatchDogLogo.propTypes = exact(propTypes)
KeywordWatchDogLogo.defaultProps = defaultProps

export default React.memo(KeywordWatchDogLogo)