import React, { memo, useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Formik, Field, ErrorMessage } from 'formik';

const propTypes = {
  user: PropTypes.object,
  submitUrl: PropTypes.string,
  authenticityToken: PropTypes.string
}

const defaultProps = {}

function NewPasswordForm ({
  user,
  submitUrl,
  authenticityToken
}) {
  const formRef = useRef()

  function handleSubmit() {
    // @ts-ignore
    formRef.current.submit()
  }

  function validate({ user }) {
    const errors = {user: {}}
    if (!user.email) {
      errors.user.email = 'Required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(user.email)
    ) {
      errors.user.email = 'Invalid email address';
    }
    
    return errors;
  }

  return (
    <Formik
      initialValues={{
        user
      }}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({
        isSubmitting,  /* and other goodies */
      }) => (
        <form action={submitUrl} method='post' >
          <input
            type="hidden"
            name="authenticity_token"
            value={authenticityToken}
          ></input>
          <Field
            type="email" 
            name="user[email]"
          />
          <ErrorMessage name="user[email]" component="div" />
          <button type="submit" disabled={isSubmitting}>
            Send me reset password instructions
          </button>
        </form>
      )}
    </Formik>
  )
}

NewPasswordForm.propTypes = exact(propTypes)
NewPasswordForm.defaultProps = defaultProps

export default memo(NewPasswordForm)