import React, { memo } from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'

const propTypes = {
  controllerName: PropTypes.string,
  isRegisterable: PropTypes.bool,
  isRecoverable: PropTypes.bool,
  isConfirmable: PropTypes.bool,
  isLockable: PropTypes.bool
}

const defaultProps = {}

function LoginLinks ({
  controllerName,
  isRegisterable,
  isRecoverable,
  isConfirmable,
  isLockable
}) {

  function Login() {
    return <a href='/users/sign_in'> Log In </a>
  }

  function SignUp() {
    return <a href='/users/sign_up'> Sign Up </a>
  }

  function ForgotPassword() {
    return <a href='/users/password/new'> Forgot your password? </a>
  }

  function ConfirmationInstructions() {
    return <a href='/users/confirmation/new'> Didn't receive confirmation instructions? </a>
  }

  function Unlock() {
    return <a href='/users/unlock/new'> Didn't receive unlock instructions? </a>
  }

  // If we ever need omniauthable? to be "true" then uncomment below
  // function Omniauth() {
  //   return omniauthProviders.map((provider) => {
  //     return <button onClick={() => {
  //       // redirect to omniauth_authorize_path(resource_name, provider)
  //     }}> Sign in with {provider} </button>
  //   })
  // }

  return (
    <>
      { controllerName != 'sessions' && <Login/> }
      { isRegisterable && <SignUp/> }
      { isRecoverable && <ForgotPassword/> }
      { isConfirmable && <ConfirmationInstructions/> }
      { isLockable && <Unlock/> }
    </>
  )
}

LoginLinks.propTypes = exact(propTypes)
LoginLinks.defaultProps = defaultProps

export default memo(LoginLinks)