import React, { memo } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import Application from '../Application'
import { LoginForm } from 'forms'
import { LoginLinks } from 'components'

const propTypes = {
  resource: PropTypes.object.isRequired,
  submitUrl: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  controllerName: PropTypes.string,
  isRecoverable: PropTypes.bool,
  isConfirmable: PropTypes.bool,
  isRegisterable: PropTypes.bool,
  isLockable: PropTypes.bool
}

const defaultProps = {}

function New ({
  resource,
  controllerName,
  isRecoverable,
  isConfirmable,
  isRegisterable,
  isLockable,
  submitUrl,
  authenticityToken
}) {
  return (
    <Application>
      <LoginForm user={resource} submitUrl={submitUrl} authenticityToken={authenticityToken} />
      <LoginLinks
        controllerName={controllerName} 
        isRecoverable={isRecoverable}
        isConfirmable={isConfirmable}
        isRegisterable={isRegisterable}
        isLockable={isLockable}
      />
    </Application>
  )
}

New.propTypes = exact(propTypes)
New.defaultProps = defaultProps

export default memo(New)