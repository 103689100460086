import React, { memo } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import ReactModal from 'react-modal'
import useViewPort from '../utils/viewPort'

const propTypes = {}

const defaultProps = {}

function SignUpModal({
  showModal,
  setShowModal
}) {
  const width = useViewPort()
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: width > 1023 ? '50%' : '75%',
      height: 'auto',
      borderRadius: '1rem',
    },
  };

  return (
    <ReactModal
      isOpen={showModal}
      onRequestClose={() => {
        setShowModal(false)
      }}
      style={customStyles}
      contentLabel="Give us your email and we'll email you when it's ready"
    >
      <div className='flex w-full flex-col justify-center items-center rounded-xl p-7 box-border'>
        <h1 className='text-darkblue text-13xl font-bold font-sora m-0 text-center'>We're still building.</h1>
        <h1 className='text-darkblue text-13xl font-sora font-bold m-0 text-center'>Check back in May.</h1>
        <span className='font-medium text-lg text-coolgray-90 mt-2 text-center'> Give us your email and we'll email you when it's ready </span>
        <form className='flex w-full flex-col md:flex-row my-6 box-border'>
          <div className='flex relative flex-1 mr-4'>
            <label htmlFor='email'> </label>
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <img src='/images/email-icon.svg' />
            </div>
            <input id='email' type='text' placeholder='Email' className='font-medium block w-full p-4 ps-10 text-sm border rounded-lg' />
          </div>
          <div className='flex mt-4 md:mt-0'>
            <button role='submit' className='font-medium rounded-lg border-sandybrown bg-sandybrown px-4 py-4 outline-none border-none cursor-pointer w-full md:w-auto'>
              Subscribe
            </button>
          </div>
        </form>
        <span className='font-medium text-[#4D5358] text-sm underline cursor-pointer' onClick={() => {
          setShowModal(false)
        }}> No, thanks. </span>
      </div>
    </ReactModal>
  )
}

SignUpModal.propTypes = exact(propTypes)
SignUpModal.defaultProps = defaultProps

export default memo(SignUpModal)
