import 'react-tippy/dist/tippy.css'

import React, { memo, useState } from 'react'
import Application from './Application'
import HeroSection from '../components/HeroSection'
import classNames from 'classnames'
import { findIndex, indexOf, isEmpty, isNumber, round } from 'lodash'
import {
  Tooltip,
} from 'react-tippy';
import TryItForFreeButton from '../components/TryItForFreeButton'
import PriceSlider from '../components/PriceSlider'
import KeywordWatchDogLogo from '../components/KeywordWatchDogLogo'
import { calculatePrice } from '../utils/calculatePrice'
import useViewPort from '../utils/viewPort'
import ReactModal from 'react-modal'

const propTypes = {}

const defaultProps = {}

function Pricing() {
  let toggled = localStorage.getItem("toggle") || 'false'
  toggled = toggled == 'true' ? true : false
  const [toggle, setToogle] = useState(toggled || false)
  const [frequency, setFrequency] = useState('daily') // ['daily', 'weekly', 'monthly']
  const [keywordCount, setKeywordCount] = useState(150)
  const [websiteCount, setWebsiteCount] = useState(1)
  const [showPriceBreakdown, setShowPriceBreakdown] = useState(false)
  const [showFrequencyOptions, setShowFrequencyOptions] = useState(false)
  const multiplier_factor = toggle ? 100 : 1
  const frequencies = ['daily', 'weekly', 'monthly']
  const KEYWORD_PRICING_WITH_FREQUENCY = {
    daily: {
      first_1000: (0.21 * multiplier_factor).toFixed(2),
      next_1000: (0.11 * multiplier_factor).toFixed(2)
    },
    weekly: {
      first_1000: (0.11 * multiplier_factor).toFixed(2),
      next_1000: (0.09 * multiplier_factor).toFixed(2)
    },
    monthly: {
      first_1000: (0.066 * multiplier_factor).toFixed(3),
      next_1000: (0.004 * multiplier_factor).toFixed(3)
    }
  }

  const competitorsArray = [{
    id: 1,
    name: 'Moz',
    monthly_price: 25,
    price_per_kw: 0.21,
  }, {
    id: 2,
    name: 'SemRUSH',
    monthly_price: 25,
    price_per_kw: 0.21,
  }, {
    id: 3,
    name: 'aHREF',
    monthly_price: 25,
    price_per_kw: 0.21,
  }, {
    id: 4,
    name: 'SERanking',
    monthly_price: 25,
    price_per_kw: 0.21,
  }, {
    id: 5,
    name: 'Keyword.com',
    monthly_price: 25,
    price_per_kw: 0.21,
  }, {
    id: 6,
    name: 'Mangools',
    monthly_price: 25,
    price_per_kw: 0.21,
  }]

  const [currentCompetitor, setCurrentCompetitor] = useState(competitorsArray[0])
  const [keywords, setKeywords] = useState(150)

  function handleToggle(e) {
    localStorage.setItem("toggle", e.target.checked)
    setToogle(e.target.checked)
  }

  function handleKeywordCountChange(value) {
    if (value == null) {
      setKeywordCount(1)
    }
    else if (value >= 1 && value <= 2500) {
      setKeywordCount(value)
    }
  }

  function handleWebsiteCountChange(value) {
    if (value == null) {
      setWebsiteCount('')
    }
    else {
      if (value >= 0) {
        setWebsiteCount(value)
      }
    }
  }

  const width = useViewPort()
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: width > 1023 ? '50%' : '75%',
      height: 'auto',
      borderRadius: '1rem',
    },
  };

  return (
    <Application>
      <ReactModal
        isOpen={showPriceBreakdown}
        onRequestClose={() => {
          setShowPriceBreakdown(false)
        }}
        style={customStyles}
        contentLabel="Your price breakdown"
      >
        <div className='flex px-12 py-14 font-[Inter] flex-col gap-14'>
          <h2 className='text-13xl font-bold m-0'>
            Price Breakdown
          </h2>
          <div className='flex rounded-3xs border-solid border-black gap-8 flex-col items-center py-9'>
            <div className='flex flex-col gap-3'>
              <div className='flex'>
                <span className='text-[64px] font-bold text-center'>${calculatePrice(keywords)}</span> <span className='text-base self-center'> /per month </span>
              </div>
              <span className='text-[#333] text-xl font-bold text-center'>
                {keywords} KW / {frequency}
              </span>
              <span className='text-base text-center'>
                Billing minimum is $9.00/mo.
              </span>
            </div>
            <div className='border-solid border-0 border-b-[2px] border-black h-[2px] w-2/3' />
            <div className='flex flex-col gap-2'>
              <div className='flex gap-4'>
                <span className='text-5xl self-center'> First 1,000 Keywords </span>
                <span className='text-[#475AFF] text-[36px] font-bold'> $0.21 </span>
              </div>
              <div className='flex gap-4'>
                <span className='text-5xl self-center'> Next 1,000 Keywords </span>
                <span className='text-[#475AFF] text-[36px] font-bold'> $0.11 </span>
              </div>
              <div className='flex gap-4'>
                <span className='text-5xl self-center'> Unlimited Websites Tracked </span>
              </div>
            </div>
          </div>
          <div className='flex justify-between'>
            <div className='flex gap-4'>
              <img src='/images/bulk-discounts.svg' />
              <span className='font-sora text-xl font-bold self-center whitespace-nowrap'>
                Bulk discounts available for <br /> accounts with 2,000+ keywords
              </span>
            </div>
            <TryItForFreeButton copy='Start Free Trial' />
          </div>
        </div>
      </ReactModal>
      <HeroSection>
        <div className="w-full flex flex-col items-center justify-start md:px-[156px] 2xl:py-[120px] md:py-[80px] py-20 box-border relative gap-[0.63rem] 2xl:px-80">
          <div className="hidden md:flex self-stretch flex-col items-start justify-start gap-[1.38rem]">
            <div className='flex flex-col justify-center w-full items-center'>
              <div className="relative md:text-[3rem] text-[24px] tracking-[0.2px] font-semibold w-full mb-6 flex gap-16">
                <div className='flex flex-1 flex-col gap-6'>
                  <h3 className='mt-0 mb-0 font-sora font-bold leading-[46.2px] text-[42px] whitespace-nowrap'>
                    Only pay for the <br />
                    keywords you use <br />
                    each month.
                  </h3>
                  <p className='text-xl font-semibold leading-6 mt-0'>
                    No more overly expensive bundles that <br />
                    include keyword capacity you don’t use.
                  </p>
                </div>
                <div className='flex flex-col gap-9 py-9 px-20 justify-center align-center rounded-xl opacity-15 bg-[rgba(217,217,217,0.2)]'>
                  <span className='text-lg font-sora font-bold'> First 30 Keywords Free For 90 Days <br /> Pay only for keywords you track </span>
                  <TryItForFreeButton copy='Start Free Trial' />
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-[10px] md:hidden text-center'>
            <h1 className='text-white font-sora text-[32px] font-bold leading-[35.2px]'>
              On Demand Pricing <br />
              No More Wasted Dollars
            </h1>
            <span className='text-white text-center font-[Inter] text-[14px] leading-[19.6px]'>
              Pay only for keywords you track
            </span>
            <span className='text-white text-center text-[16px] font-[Inter] font-semibold'>
              First 30 Keywords Free For 90 Days
            </span>
            <div className='flex justify-center items-center mt-7'>
              <TryItForFreeButton copy='Start Free Trial' />
            </div>
          </div>
          <div className='flex w-full mt-20 flex-col gap-12'>
            <div className='flex justify-between'>
              <span className='text-black text-[20px] font-sora font-semibold'>
                Number of keywords
              </span>
              <div className='flex'>
                <span className='text-sm w-[13.125rem] text-black'> Update Frequency: </span>
                <div className='flex relative items-center gap-2' onClick={() => {
                  setShowFrequencyOptions(!showFrequencyOptions)
                }}>
                  <p className='text-base font-normal m-0 text-black'> {frequency} </p>
                  <img className={classNames({
                    'rotate-180': showFrequencyOptions,
                    'cursor-pointer': true
                  })} src='/images/arrow-down.svg' />
                  {
                    showFrequencyOptions && (
                      <div className='bg-white p-4 items-center absolute top-[42px] left-[-20px] min-w-fit box-border transition-transform z-10 text-black rounded-3xs shadow-md'>
                        <div className='flex relative'>
                          <span id='select-frequency-span' className='whitespace-nowrap font-[Inter] text-base font-semibold text-[#18141F] mr-5'> Select Frequency </span>
                          <Tooltip
                            // options
                            html={(
                              <div className='text-white bg-[#475AFF] p-4 box-border text-sm rounded-md z-20 font-[Inter] whitespace-nowrap'>
                                <span>
                                  Frequency at which data in databases is updated. Latest updates are essential to<br />
                                </span>
                                <span>
                                  make informed decisions.
                                </span>
                              </div>
                            )}
                            interactive
                            position='top-start'
                            trigger='mouseenter'
                          >
                            <img data-tooltip-id="information" className='' src='/images/information-outline.svg' width={24} height={24} />
                          </Tooltip>
                        </div>
                        <div className='my-4 border-solid border-0 border-b-[1px] border-[#18141F]' />
                        {
                          frequencies.map((frequency, index) => {
                            return (
                              <p key={index} className='font-[Inter] text-base font-semibold text-[#18141F] cursor-pointer' onClick={() => {
                                setFrequency(frequency)
                                setShowFrequencyOptions(false)
                              }}> {frequency} </p>
                            )
                          }
                          )
                        }
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
            <PriceSlider keywords={keywords} setKeywords={setKeywords} black_variant />
            {
              (keywords >= 2500) && (
                <span className='font-bold text-black text-center'> <a href='mailto:sales@keywordwatchdog.com' className='text-black'> Contact </a> our sales team for pricing of volumes of 2,500+ KWs or more. </span>
              )
            }
          </div>
        </div>
      </HeroSection>
      <div className="w-full flex flex-col items-center justify-start md:px-[156px] md:pb-[70px] p-[16px] box-border relative gap-[0.63rem] bg-[#D9D9D9] md:pt-0">
        <div className='flex gap-7 mt-20 flex-col md:flex-row'>
          <div className='flex flex-col text-center md:w-[500px] w-[350px] relative'>
            <div className='flex absolute left-[33px]'>
              <img src='/images/best-value-rivon.svg' height={155} />
            </div>
            <div className='flex bg-[#475AFF] py-4 rounded-t-[16px] items-center justify-center h-[100px]'>
              <KeywordWatchDogLogo white_variant />
            </div>
            <div className='flex gap-4 font-[Inter] text-[16px] bg-white flex-col text-black px-16 py-14 rounded-b-[16px]'>
              {
                (isNumber(calculatePrice(keywords)) && calculatePrice(keywords) < 9) && (
                  <span className='font-bold'> Minimum Billing is $9.00/month </span>
                )
              }
              <span className='font-bold text-[64px]'> ${calculatePrice(keywords)} </span>
              <span className='text-[10px] tracking-[4px]'> PER MONTH </span>
              <span className=''> {round(calculatePrice(keywords) / keywords, 2)} / Keyword </span>
              <span className='font-bold'> You get everything, you just pay per kw </span>
              <button className='text-[#475AFF] font-[Inter] text-[16px] font-semibold tracking-[0.5px] rounded-[10px] border-solid border-[2px] mt-2 bg-white border-[#475AFF] px-[12px] py-[16px] text-center' onClick={() => {
                setShowPriceBreakdown(true)
              }}>
                Price Breakdown
              </button>
            </div>
          </div>
          <div className='flex items-center justify-center text-[#18141F] font-[Sora] text-[40px] font-thin'>
            VS
          </div>
          <div className='flex flex-col text-center md:w-[500px] w-[350px] relative'>
            <div className='flex absolute left-0 mt-auto mb-auto top-0 bottom-0 justify-center cursor-pointer items-center rotate-180' onClick={() => {
              const index = findIndex(competitorsArray, (competitor) => competitor.id == currentCompetitor.id)
              if (index == 0) {
                setCurrentCompetitor(competitorsArray[competitorsArray.length - 1])
              } else {
                if (index - 1 >= 0) {
                  setCurrentCompetitor(competitorsArray[index - 1])
                } else {
                  setCurrentCompetitor(competitorsArray[0])
                }
              }
            }}>
              <img src='/images/chevron-right.svg' width={50} height={50} />
            </div>

            <div className='flex absolute right-0 mt-auto mb-auto top-0 bottom-0 justify-center items-center cursor-pointer' onClick={() => {
              const index = findIndex(competitorsArray, (competitor) => competitor.id == currentCompetitor.id)
              if (index == competitorsArray.length - 1) {
                setCurrentCompetitor(competitorsArray[0])
              } else {
                setCurrentCompetitor(competitorsArray[index + 1])
              }
            }}>
              <img src='/images/chevron-right.svg' width={50} height={50} />
            </div>

            {
              frequency == 'monthly' ? (
                <div className='flex flex-col gap-6 font-[Inter] text-black text-base bg-white rounded-t-[16px] rounded-b-[16px] items-center h-full justify-center p-14'>
                  <img src='/images/no-competitor-found.svg' />
                  <span className='font-bold'> There are <span className='text-[#F29749]'> no competitors </span> that have a monthly pricing </span>
                  <span> We are the only ones that offers monthly rates. </span>
                </div>
              ) : (
                <>
                  <div className='flex text-black bg-[#E4DCCE] py-4 rounded-t-[16px] text-xl items-center justify-center h-[100px] font-bold font-sora'>
                    {currentCompetitor.name}
                  </div>
                  <div className='flex gap-4 font-[Inter] text-[16px] bg-white flex-col text-black px-16 py-14 rounded-b-[16px]'>
                    <span className='font-bold text-[64px]'> ${currentCompetitor.monthly_price} </span>
                    <span className='text-[10px] tracking-[4px]'> PER MONTH </span>
                    <span className=''> ${currentCompetitor.price_per_kw} / Keyword </span>
                    <div className='font-[Inter] text-[16px] font-semibold tracking-[0.5px] rounded-[10px] px-[12px] py-[16px] text-center mt-5'>
                      They Just <br /> Charge <span className='text-[#F2994A]'> More </span>
                    </div>
                  </div>
                </>
              )
            }

          </div>
        </div>
        <div className='flex flex-col w-fit mt-24 px-[54px] box-border'>
          <div className='flex w-full items-center'>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-l-[1px] border-[#18141F]'>
              <img src='/images/purple-check.svg' width={32} height={74} />
            </div>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-l-[1px] border-r-[1px] border-[#18141F] h-[78.5px] text-center flex justify-center items-center w-[428.13px]'>
              <span className='text-[#18141F] text-center font-[Sora] text-[16px]'> Unlimited Website Tracking </span>
            </div>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-r-[1px] border-[#18141F]'>
              <img src='/images/window-close.svg' width={32} height={74} />
            </div>
          </div>
          <div className='flex w-full items-center'>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-l-[1px] border-[#18141F]'>
              <img src='/images/purple-check.svg' width={32} height={74} />
            </div>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-l-[1px] border-r-[1px] border-[#18141F] h-[78.5px] text-center flex justify-center items-center w-[428.13px]'>
              <span className='text-[#18141F] text-center font-[Sora] text-[16px]'> Keyword Tracking </span>
            </div>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-r-[1px] border-[#18141F]'>
              <img src='/images/check.svg' width={32} height={74} />
            </div>
          </div>
          <div className='flex w-full items-center'>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-l-[1px] border-[#18141F]'>
              <img src='/images/purple-check.svg' width={32} height={74} />
            </div>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-l-[1px] border-r-[1px] border-[#18141F] h-[78.5px] text-center flex justify-center items-center w-[428.13px]'>
              <span className='text-[#18141F] text-center font-[Sora] text-[16px]'> Location Specific Tracking </span>
            </div>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-r-[1px] border-[#18141F]'>
              <img src='/images/window-close.svg' width={32} height={74} />
            </div>
          </div>
          <div className='flex w-full items-center'>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-l-[1px] border-[#18141F]'>
              <img src='/images/purple-check.svg' width={32} height={74} />
            </div>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-l-[1px] border-r-[1px] border-[#18141F] h-[78.5px] text-center flex justify-center items-center w-[428.13px]'>
              <span className='text-[#18141F] text-center font-[Sora] text-[16px]'> Share Live Report </span>
            </div>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-r-[1px] border-[#18141F]'>
              <img src='/images/window-close.svg' width={32} height={74} />
            </div>
          </div>
          <div className='flex w-full items-center'>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-l-[1px] border-[#18141F]'>
              <img src='/images/purple-check.svg' width={32} height={74} />
            </div>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-l-[1px] border-r-[1px] border-[#18141F] h-[78.5px] text-center flex justify-center items-center w-[428.13px]'>
              <span className='text-[#18141F] text-center font-[Sora] text-[16px]'> Device Tracking </span>
            </div>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-r-[1px] border-[#18141F]'>
              <img src='/images/window-close.svg' width={32} height={74} />
            </div>
          </div>
          <div className='flex w-full items-center'>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-l-[1px] border-[#18141F]'>
              <img src='/images/purple-check.svg' width={32} height={74} />
            </div>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-l-[1px] border-r-[1px] border-[#18141F] h-[78.5px] text-center flex justify-center items-center w-[428.13px]'>
              <span className='text-[#18141F] text-center font-[Sora] text-[16px]'> Google Search Console Integration </span>
            </div>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-r-[1px] border-[#18141F]'>
              <img src='/images/check.svg' width={32} height={74} />
            </div>
          </div>
          <div className='flex w-full items-center'>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-l-[1px] border-[#18141F]'>
              <img src='/images/purple-check.svg' width={32} height={74} />
            </div>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-l-[1px] border-r-[1px] border-[#18141F] h-[78.5px] text-center flex justify-center items-center w-[428.13px]'>
              <span className='text-[#18141F] text-center font-[Sora] text-[16px]'> Branded Reports </span>
            </div>
            <div className='px-[10px] md:px-[70px] border-solid border-0 border-r-[1px] border-[#18141F]'>
              <img src='/images/check.svg' width={32} height={74} />
            </div>
          </div>
        </div>
        <span className='text-[#666] w-[330px] flex flex-col self-center mt-[35px] text-center font-[Inter] text-[11px] leading-[12.1px] whitespace-nowrap gap-1'>
          *Last updated December 2023. <br /> If you notice anything incorrect in the pricing, <a href='mailto:info@keywordwatchdog.com' className='text-[#666]'> email us </a> so we can update our pricing page accordingly
        </span>
      </div>
      <div className='flex flex-col md:flex-row justify-between px-[29px] py-[64px] gap-20 md:py-[87px] md:px-[156px] bg-[#475AFF] 2xl:px-80'>
        <div className='text-white font-[Inter] font-semibold text-[36px]'>
          Try Our 90 Days Free <br /> for your first 30 Keywords
        </div>
        <div className='flex self-center'>
          <TryItForFreeButton copy='Start Free Trial' />
        </div>
      </div>
      <div className="flex w-full flex-col bg-[#1C1824] px-[16px] md:px-[156px] md:py-[87px] box-border pt-20 pb-20 2xl:px-80">
        <div className="mb-10 w-full flex flex-col items-start justify-start gap-[3rem] z-[3] text-center text-[0.75rem] text-white">
          <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem]">
            <div className="self-stretch relative text-[2rem] leading-[110%] font-semibold font-sora">
              FAQ
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col wrap">
          <details className="flex w-full px-[2rem] py-[3rem] rounded-xl bg-white justify-between items-center mb-4 box-border">
            <summary className="font-sora text-lg font-semibold">
              Mauris quam leo aliquet eget consectetur.
            </summary>
            <span className="flex flex-1 mt-10 text-dimgray font-normal text-base leading-6 basis-full font-medium">
              Mauris quam leo aliquet eget consectetur.
            </span>
          </details>
          <details className="flex w-full px-[2rem] py-[3rem] rounded-xl bg-white justify-between items-center flex-wrap mb-4 box-border">
            <summary className="font-sora text-lg font-semibold">
              Mauris quam leo aliquet eget consectetur.
            </summary>
            <span className="flex flex-1 mt-10 text-dimgray font-normal text-base leading-6 basis-full font-medium">
              Mauris quam leo aliquet eget consectetur.
            </span>
          </details>
          <details className="flex w-full px-[2rem] py-[3rem] rounded-xl bg-white justify-between items-center mb-4 box-border">
            <summary className="font-sora text-lg font-semibold">
              Mauris quam leo aliquet eget consectetur.
            </summary>
            <span className="flex flex-1 mt-10 text-dimgray font-normal text-base leading-6 basis-full font-medium">
              Mauris quam leo aliquet eget consectetur.
            </span>
          </details>
          <details className="flex w-full px-[2rem] py-[3rem] rounded-xl bg-white justify-between items-center mb-4 box-border">
            <summary className="font-sora text-lg font-semibold">
              Mauris quam leo aliquet eget consectetur.
            </summary>
            <span className="flex flex-1 mt-10 text-dimgray font-normal text-base leading-6 basis-full font-medium">
              Mauris quam leo aliquet eget consectetur.
            </span>
          </details>
          <details className="flex w-full px-[2rem] py-[3rem] rounded-xl bg-white justify-between items-center mb-4 box-border">
            <summary className="font-sora text-lg font-semibold">
              Mauris quam leo aliquet eget consectetur.
            </summary>
            <span className="flex flex-1 mt-10 text-dimgray font-normal text-base leading-6 basis-full font-medium">
              Mauris quam leo aliquet eget consectetur.
            </span>
          </details>
          <details className="flex w-full px-[2rem] py-[3rem] rounded-xl bg-white justify-between items-center box-border">
            <summary className="font-sora text-lg font-semibold">
              Mauris quam leo aliquet eget consectetur.
            </summary>
            <span className="flex flex-1 mt-10 text-dimgray font-normal text-base leading-6 basis-full font-medium">
              Mauris quam leo aliquet eget consectetur.
            </span>
          </details>
        </div>
      </div>
    </Application>
  )
}

Pricing.propTypes = propTypes
Pricing.defaultProps = defaultProps

export default memo(Pricing)
