import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classNames from 'classnames';
import SignUpModal from './SignUpModal';

const propTypes = {
  sandygold: PropTypes.bool,
}

const defaultProps = {
  sandygold: false,
}

function SignUpButton({
  sandygold
}) {
  const [showModal, setShowModal] = useState(false)

  return (
    <div className={classNames("rounded-3xs bg-mediumslateblue-100 box-border h-[3rem] flex flex-row items-center justify-center border-[2px] border-solid border-mediumslateblue-100", {
      'border-sandybrown': sandygold,
      'bg-sandybrown': sandygold,
      'my-3': sandygold,
    })}>
      <SignUpModal showModal={showModal} setShowModal={setShowModal} />
      <div className="flex flex-row items-center justify-center w-full h-full">
        <button className={classNames("py-[1rem] px-[2rem] relative tracking-[0.5px] leading-[100%] font-semibold cursor-pointer w-full h-full border-none bg-transparent text text-base font-sora whitespace-nowrap", {
          'text-black': sandygold,
          'text-light-text': !sandygold,
        })} onClick={() => {
          setShowModal(true)
        }}>
          Sign Up
        </button>
      </div>
    </div>
  )
}

SignUpButton.propTypes = exact(propTypes)
SignUpButton.defaultProps = defaultProps

export default memo(SignUpButton)