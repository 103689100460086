import React from 'react'
import { connect } from 'react-redux'
import { increment, decrement, reset } from './../store/actions'
import { select } from '../store/HelloWorld/selectors'

const propTypes = {}

const defaultProps = {}

function HelloMessage ({
  message,
  value,
  increment,
  decrement,
  reset
}) {
  return (
    <>
      <p> {message} </p>

      <p> Hello Count: {value} </p>
      <button onClick={() => increment(value)}>Increment</button>
      <button onClick={() => decrement(value)}>Decrement</button>
      <button onClick={reset}>Reset</button>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    value: select(state, 'value')
  }
}

const mapDispatchToProps = {
  increment,
  decrement,
  reset
}

HelloMessage.propTypes = propTypes
HelloMessage.defaultProps = defaultProps

export default connect(mapStateToProps, mapDispatchToProps)(HelloMessage)