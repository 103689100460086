import React, { memo, useState } from 'react'
import Application from './Application'
import HeroSection from '../components/HeroSection'
import { CircularSliderWithChildren } from 'react-circular-slider-svg';
import Carousel from '../components/Carousel';
import TryItForFreeButton from '../components/TryItForFreeButton';
import Banner from '../components/Banner'; // Adjust the path as necessary
import { calculatePrice } from '../utils/calculatePrice';
import PriceSlider from '../components/PriceSlider';
const propTypes = {}

const defaultProps = {}

function LandingPage() {
  // convert keywordsSelected to integer
  const keywordsSelected = parseInt(localStorage.getItem("keywordsSelected") || '1')
  const [keywords, setKeywords] = useState(keywordsSelected || 1);

  function calculateCompetitorsPrice() {
    if (keywords <= 200) {
      return 44.00
    } else if (keywords <= 500) {
      return 130.00
    } else if (keywords <= 1000) {
      return 131.00
    } else if (keywords <= 1250) {
      return 249.00
    } else if (keywords <= 1500) {
      return 250.00
    } else if (keywords <= 1750) {
      return 299.00
    } else if (keywords <= 2500) {
      return 307.00
    }
  }

  function handleSetKeywords(v) {
    if (v) {
      localStorage.setItem("keywordsSelected", v.toFixed(2).toString())
      setKeywords(v)
    }
  }

  return (
    <Application>
      <Banner /> {/* Add the Banner component here */}
      <HeroSection>
        <div className='flex md:flex-row flex-col w-full box-border'>
          <div className="lg:w-[90rem] w-full h-auto flex flex-col items-center justify-start p-[16px] lg:p-[5rem] lg:pr-0 box-border relative gap-[0.63rem] text-[2.63rem]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[1.38rem]">
              <div className='flex flex-wrap lg:flex-nowrap w-full lg:w-auto'>
                <div className='md:w-full'>
                  <h1 className="w-full relative md:text-[2.5rem] text-[2rem] tracking-[0.2px] font-semibold inline-block mb-6">
                    <span>{`The `}</span>
                    <span className="text-sandybrown">only</span>
                    <span>
                      {" "}
                      keyword tracking tool that doesn’t eat your entire budget.
                    </span>
                  </h1>
                  <div className="box-border flex flex-row items-center justify-center text-[1.25rem] text-gray-300 w-fit">
                    <div className="box-border flex flex-row items-center justify-center text-[1.25rem] text-gray-300 w-fit mt-8">
                      <TryItForFreeButton/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-8 w-full flex justify-center'>
            <img className='md:w-[70%] w-[85%] h-[90%]' src='/images/hero-section-image.png' />
          </div>
        </div>
      </HeroSection>
      <div className="bg-light-text self-stretch flex flex-row items-start justify-center text-gray-300 flex-wrap lg:flex-nowrap">
        <div className="self-stretch flex-1 flex flex-col items-center py-[2.5rem] px-[1rem] md:py-[5rem] md:px-[3rem] gap-[3rem] justify-center">
          <div className="font-sora self-stretch flex flex-col items-start justify-start gap-[3rem] text-center text-darkblue">
            <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem]">
              <h2 className="self-stretch relative text-[2rem] leading-[110%] font-semibold">
                <p className="[margin-block-start:0] [margin-block-end:5px]">
                   We Track Keywords.
                </p>
                <p className="m-0">They Track Words...</p>
              </h2>
            </div>
          </div>
          {/* Hides elements on mobile */}
          <div className="self-stretch rounded-11xl lg:flex flex-col items-center justify-start pt-[0rem] px-[0rem] pb-[2rem] gap-[0.63rem] text-[1.06rem] font-sora hidden">
            <b className="relative tracking-[0.2px] leading-[1.56rem]">
              {keywords} Keywords per year
            </b>
            <PriceSlider keywords={keywords} setKeywords={setKeywords} />
          </div>
        </div>
        <div className='self-stretch flex-1 bg-mediumslateblue-100 flex lg:hidden flex-col items-center justify-center py-[5rem] px-[0rem] relative text-center text-light-text white-stripes-background basis-full'>
          <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem] mb-[2rem] ">
            <div className="self-stretch relative text-[2rem] leading-[110%] font-semibold text-light-text font-sora">
              <span>{`They just charge `}</span>
              <span className="text-sandybrown">more</span>
              <span>.</span>
            </div>
          </div>
          <b className="relative tracking-[0.2px] leading-[1.56rem] font-medium">
            {keywords} Keywords per year
          </b>
          {/* Circular slider price for mobile */}
          <div id='circular-price-slider' className='relative block lg:hidden'>
            <CircularSliderWithChildren
              handle1={{
                value: keywords,
                onChange: v => handleSetKeywords(Math.ceil(v))
              }}
              trackWidth={28}
              minValue={1}
              maxValue={2500}
              arcColor="#F4BD6B"
              arcBackgroundColor="#3B48C7"
              handleSize={18}
              size={360}
              startAngle={40}
              endAngle={320}
            >
              <div className='flex flex-col gap-4 -mt-9'>
                <div className='flex flex-col text-white items-center'>
                  <img className='w-[25px] h-[28px] mb-2' src='/images/best-price-icon.svg' />
                  <span className='font-medium'> Keyword<span className='font-bold'>Watchdog</span> </span>
                  <span className='font-sora font-semibold text-5xl'> ${calculatePrice(keywords)}/mo. </span>
                </div>
                <div className='flex flex-col text-[#4D5358]'>
                  <span className='font-sora font-semibold text-5xl'> ${calculateCompetitorsPrice()}/mo. </span>
                  <span className='font-medium font-bold'> Competitor </span>
                </div>
              </div>
            </CircularSliderWithChildren>
            <div className='absolute flex w-full justify-around bottom-3'>
              <span className='font-sora font-bold text-sm'>
                1 KW
              </span>
              <span className='font-sora font-bold text-sm'>
                2,500 KW
              </span>
            </div>
          </div>
        </div>
        {/* Hidden on mobile */}
        <div className="self-stretch flex-1 bg-mediumslateblue-100 hidden lg:flex flex-col items-center justify-center py-[5rem] px-[0rem] relative gap-[2.5rem] text-center text-light-text white-stripes-background">
          <div className="self-stretch flex flex-col items-start justify-start gap-[3rem] text-coolgray-80">
            <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem]">
              <div className="self-stretch relative text-[2rem] leading-[110%] font-semibold text-light-text font-sora">
                <span>{`They just charge `}</span>
                <span className="text-sandybrown">more</span>
                <span>.</span>
              </div>
            </div>
          </div>
          <div className="rounded-2xl bg-light-text shadow-[0px_0px_34px_rgba(0,_0,_0,_0.15)] w-[32.69rem] overflow-hidden flex flex-col items-center justify-start text-[1.25rem]">
            <div className="self-stretch h-[4.38rem] flex flex-row items-center justify-center font-sora">
              <div className="self-stretch flex-1 bg-navy flex flex-col items-center justify-center">
                <div className="relative leading-[5.5rem]">
                  <span>Keyword</span>
                  <b>Watchdog</b>
                </div>
              </div>
              <div className="self-stretch flex-1 rounded-tl-none rounded-tr-2xl rounded-b-none overflow-hidden flex flex-col items-center justify-center">
                <div className="self-stretch flex-1 rounded-tl-none rounded-tr-2xl rounded-b-none bg-coolgray-80 flex flex-row items-center justify-center">
                  <div className="relative leading-[5.5rem] inline-block w-[14.81rem] shrink-0">
                    Competitor
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-start text-[2.25rem] text-gray-300 font-sora">
              <div className="flex-1 bg-light-text box-border h-[6.5rem] flex flex-row items-center justify-center py-[3.75rem] px-[0rem] gap-[1.25rem] border-r-[1px]">
                <img
                  className="relative w-[1.88rem] h-[2.09rem]"
                  alt=""
                  src="/images/group-143781.svg"
                />
                <div className="relative leading-[5.5rem]">
                  <b>${calculatePrice(keywords)}</b>
                  <span className="text-[1rem]">/mo.</span>
                </div>
              </div>
              <div className="flex-1 bg-coolgray-20 box-border h-[6.5rem] flex flex-col items-center justify-center py-[3.75rem] px-[0rem] text-gray-3">
                <div className="relative leading-[5.5rem]">
                  <b>${calculateCompetitorsPrice()}</b>
                  <span className="text-[1rem]">/mo.</span>
                </div>
              </div>
            </div>
          </div>
          <div className="relative leading-[140%] font-body-s text-sm text-left font-medium px-20">
            * Average Competitor Pricing is based on the median pricing amongst industry leaders. For more specific breakdowns, please visit our pricing page
          </div>
        </div>
      </div>
      {/* Trusted By Section */}
      <div className="bg-coolgray-10 self-stretch flex lg:flex-row items-center justify-center py-[5rem] box-border gap-[2rem] text-center flex-wrap overflow-hidden flex-col ">
        <div className="w-[9.25rem] flex flex-col items-start justify-start gap-[3rem]">
          <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem]">
            <b className="self-stretch relative tracking-[1px] leading-[100%] uppercase font-body-s text-coolgray-80">
              trusted by
            </b>
          </div>
        </div>
        <div className="flex-1 flex flex-row items-center justify-start gap-[1rem] md:gap-[2rem] text-left text-[1.5rem] text-coolgray-60 font-sora w-full lg:w-auto">
          <Carousel>
            <div className="cell flex-1 ml-[2rem] flex flex-row items-center justify-center gap-[0.5rem]">
              <img
                className="relative w-[1.9rem] h-[2rem]"
                alt=""
                src="/images/vector3.svg"
              />
              <div className="relative leading-[110%] font-semibold">SHELLS</div>
            </div>
            <div className="cell flex-1 ml-[2rem] flex flex-row items-center justify-center gap-[0.5rem]">
              <img
                className="relative w-[2.08rem] h-[2rem]"
                alt=""
                src="/images/group-451.svg"
              />
              <div className="relative leading-[110%] font-semibold">
                SmartFinder
              </div>
            </div>
            <div className="cell flex-1 ml-[2rem] flex flex-row items-center justify-center gap-[0.5rem]">
              <img
                className="relative w-[2rem] h-[2rem]"
                alt=""
                src="/images/vector4.svg"
              />
              <div className="relative leading-[110%] font-semibold">Zoomerr</div>
            </div>
            <div className="cell flex-1 ml-[2rem] flex flex-row items-center justify-center gap-[0.5rem]">
              <img
                className="relative w-[3.49rem] h-[2rem]"
                alt=""
                src="/images/group-461.svg"
              />
              <div className="relative leading-[110%] font-semibold">
                ArtVenue
              </div>
            </div>
            <div className="cell flex-1 ml-[2rem] flex flex-row items-center justify-center gap-[0.5rem]">
              <img
                className="relative w-[1.49rem] h-[1.83rem]"
                alt=""
                src="/images/group-471.svg"
              />
              <div className="relative leading-[110%] font-semibold">
                kontrastr
              </div>
            </div>
          </Carousel>
        </div>
      </div>
      {/* End of trusted by section */}
      {/* Beautifully designed section */}
      <div className='flex flex-col beautifully-designed-background w-full md:w-auto px-[16px] pt-[5rem] md:p-[5rem] items-center justify-start gap-[3rem] box-border'>
        <div className='flex font-sora justify-center'>
          <p className='text-light-text text-[2rem] font-semibold'> Beautifully Designed </p>
        </div>
        <Carousel onlyMobile overwriteDefaultFlickityOptions={{
          prevNextButtons: true
        }}>
          <div className="flex rounded-xl carousel-place-holder shadow-[0px_0px_34px_rgba(0,_0,_0,_0.05)] lg:w-[60rem] w-full h-[16.75rem] md:h-[33.75rem] overflow-hidden shrink-0 flex-row items-center justify-center ml-[2rem]">
          </div>
          <div className="flex rounded-xl carousel-place-holder shadow-[0px_0px_34px_rgba(0,_0,_0,_0.05)] lg:w-[60rem] w-full h-[16.75rem] md:h-[33.75rem] overflow-hidden shrink-0 flex-row items-center justify-center ml-[2rem]">
          </div>
          <div className="flex rounded-xl carousel-place-holder shadow-[0px_0px_34px_rgba(0,_0,_0,_0.05)] lg:w-[60rem] w-full h-[16.75rem] md:h-[33.75rem] overflow-hidden shrink-0 flex-row items-center justify-center ml-[2rem]">
          </div>
        </Carousel>
        <div className='flex justify-center mb-10 md:mb-0'>
          <div className="box-border flex flex-row items-center justify-center text-[1.25rem] text-gray-300 w-fit my-8">
            <TryItForFreeButton/>
          </div>
        </div>
      </div>
      {/* End of beautifully designed section */}
      <div className="self-stretch [background:linear-gradient(#fff,_#fff),_#f2f4f8] overflow-hidden flex flex-col items-center justify-start py-[5rem] px-[0rem] gap-[2.5rem] font-sora">
        <div className="w-[80rem] flex flex-col items-start justify-start gap-[3rem] text-center">
          <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem]">
            <div className="self-stretch relative text-[2rem] leading-[110%] font-semibold text-darkblue">
              Key Features
            </div>
            <a className='font-medium' href='/features'> {"See all features >"} </a>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start gap-[0.13rem] text-[2.25rem] text-midnightblue overflow-hidden md:px-[7.5rem] w-full lg:w-[80rem] md:w-auto xl:w-full xl:p-0">
          <div className="self-stretch flex flex-row items-start justify-center py-[0rem] gap-[2rem] text-[1.5rem] text-gray-300 xl:w-5/6 xl:self-center">
            <Carousel overwriteDefaultFlickityOptions={{
              prevNextButtons: true,
              percentPosition: true
            }}>
              <div className="flex w-4/6 ml-[2rem] flex-col items-start justify-start gap-[1.5rem]">
                <div className="self-stretch flex flex-col items-start justify-start">
                  <img
                    className="relative w-[3.5rem] h-[3.5rem] overflow-hidden shrink-0"
                    alt=""
                    src="/images/browse-activity-fill0-wght400-grad0-opsz24-1.svg"
                  />
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem]">
                  <span className="self-stretch relative leading-[110%] font-semibold">
                    Pay For What You Need
                  </span>
                  <span className="self-stretch relative text-[1.25rem] tracking-[0.2px] leading-[1.88rem] font-body-s text-dimgray">
                    Track daily, or weekly changes in your keyword rankings on
                    Google.
                  </span>
                </div>
              </div>
              <div className="flex w-4/6 ml-[2rem] flex-col items-start justify-start gap-[1.5rem]">
                <div className="self-stretch flex flex-col items-start justify-start">
                  <img
                    className="relative w-[3.5rem] h-[3.5rem]"
                    alt=""
                    src="/images/quality.svg"
                  />
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem]">
                  <div className="self-stretch relative leading-[110%] font-semibold">
                    Best-In-Class Data Quality
                  </div>
                  <div className="self-stretch relative text-[1.25rem] tracking-[0.2px] leading-[1.88rem] font-body-s text-dimgray">
                    Accurate data you can trust.
                  </div>
                </div>
              </div>
              <div className="flex w-4/6 ml-[2rem] flex-col items-start justify-start gap-[1.5rem] text-gray-100">
                <div className="self-stretch flex flex-col items-start justify-start">
                  <img
                    className="relative w-[3.5rem] h-[3.5rem]"
                    alt=""
                    src="/images/blood-pressure.svg"
                  />
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem]">
                  <div className="self-stretch relative leading-[110%] font-semibold">
                    Keep Tabs On Competitors
                  </div>
                  <div className="self-stretch relative text-[1.25rem] tracking-[0.2px] leading-[1.88rem] font-body-s text-dimgray">
                    Track your competitor’s rankings alongside your own.
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
      <div className="self-stretch bg-light-text overflow-hidden flex flex-col items-center justify-start py-[5rem] md:p-[5rem] box-border relative text-[1.25rem] text-gray-300 product-walkthrough-background font-sora">
        <div className="w-[56.25rem] flex flex-col items-start justify-start gap-[3rem] text-center text-[0.75rem] text-coolgray-80">
          <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem]">
            <p className="self-stretch relative text-[2rem] leading-[110%] font-semibold text-light-text">
              Product Walkthrough
            </p>
          </div>
        </div>
        <div className="rounded-xl bg-first-text shadow-[0px_0px_34px_rgba(0,_0,_0,_0.05)] lg:w-[60rem] h-[18.75rem] lg:h-[28.75rem] overflow-hidden shrink-0 flex flex-row items-center justify-center w-full">
          <img
            className="relative w-[6rem] h-[6rem]"
            alt=""
            src="/images/play-circle.svg"
          />
        </div>
        <div className="flex flex-col items-center justify-center gap-[1.5rem] text-light-text">
          <div className="box-border flex flex-row items-center justify-center text-[1.25rem] text-gray-300 w-fit mt-8">
            <TryItForFreeButton/>
          </div>
          <div className="relative text-[1rem] leading-[140%] font-body-s">
            <a href='mailto:sales@keywordwatchdog.com' className="text-light-text">Contact Us</a> For
            Enterprise Pricing
          </div>
        </div>
      </div>
      <div className="self-stretch bg-light-text overflow-hidden flex flex-col items-center justify-start px-[16px] py-[5rem] md:p-[5rem] box-border relative gap-[3rem] text-[1.25rem] text-gray-300 font-medium">
        <div className="absolute my-0 mx-[!important] right-[-4.81rem] bottom-[-17.96rem] [background:linear-gradient(180deg,_#c897ff,_#fbbfff),_linear-gradient(180deg,_#dbfda2,_#d3fbec)] [filter:blur(300px)] w-[25.31rem] h-[25.31rem] [transform:_rotate(-15.65deg)] [transform-origin:0_0] opacity-[0.3]" />
        <div className="absolute my-0 mx-[!important] top-[-5.12rem] left-[-11rem] [background:linear-gradient(180deg,_#dbfda2,_#d3fbec)] [filter:blur(300px)] w-[32.4rem] h-[32.4rem] [transform:_rotate(-15.65deg)] [transform-origin:0_0] opacity-[0.3]" />
        <div className="w-full md:w-[56.25rem] flex flex-col items-start justify-start gap-[3rem] text-center text-[0.75rem] text-darkblue">
          <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem]">
            <div className="self-stretch relative text-[2rem] leading-[110%] font-semibold font-sora">
              You’re still reading, huh?
            </div>
          </div>
        </div>
        <div className="relative text-[1rem] leading-[160%] font-body-s text-coolgray-90 whitespace-pre-wrap inline-block w-full lg:w-[56.25rem]">
          <p className="m-0">
            Well.... We ran out of landing page content so we might as well tell
            you our story about why we created KeywordWatchdog.com
          </p>
          <p className="m-0">&nbsp;</p>
          <p className="m-0">{`We hated what’s out there & how much it costs. That’s the story. That’s it... But our PR team said we need to show some personality and helped us put together something a bit more appealing. Listen, you don’t have to keep reading. But if you do, you should know we wanted to stop at “We hated what was out there and how much it cost.”`}</p>
          <p className="m-0">&nbsp;</p>
          <p className="m-0">{`Our founder started a Marketing & Analytics agency, mindfulCONVERSION,  after over a decade in marketing, product, and analytic roles for some of the biggest companies in the world. He managed $100MM+ ad budgets and helped companies like Microsoft understand how to apply streaming analytics to improve product decisions.`}</p>
          <p className="m-0">&nbsp;</p>
          <p className="m-0">{`Like many other new business, we started the hunt to find the right combinations of tools to accelerate the impact we’re having for our clients. Here’s some shocking news. We don’t like paying $6,000+ year in subscriptions for useful, insight tools that didn’t really meet the mark. They tell you interesting things, but none of it is exceptionally actionable... until you pay another $100/mo to unlock those features and then another $150/mo for the capacity you need to use them for your clients. `}</p>
          <p className="m-0">&nbsp;</p>
          <p className="m-0">
            And to be honest, many of them aren’t doing anything that
            interesting or complicated. So we built a prototype. And, then we
            starting using it for our own clients. And then we kind of just
            scratched our heads trying to figure out why these companies were
            charging SO MUCH for so little. So, we turned that prototype it into
            a product. And then we got to work adding on cool new features we
            wished the other tools had.
          </p>
          <p className="m-0">&nbsp;</p>
          <p className="m-0">{`There - that’s the story the PR team wanted. So you happy now Steve from PR? `}</p>
          <p className="m-0">&nbsp;</p>
          <p className="m-0">{`Anyways, there’s a morale to this story. Stop wasting your money on overly-priced SEO tools and... you know... use your budgets for holiday gifts and employee bonuses. `}</p>
        </div>
        <div className="flex md:flex-row justify-center gap-[1.5rem] flex-col w-full">
          <div className="box-border flex flex-row items-center justify-center text-[1.25rem] text-gray-300 w-auto mt-8">
            <TryItForFreeButton/>
          </div>
          <div className="box-border flex flex-row items-center justify-center text-[1.25rem] border-mediumslateblue-100 border-solid rounded-lg w-[174px] lg:w-auto self-center mt-8">
            <a href='/features' className="relative tracking-[0.5px] leading-[100%] font-semibold font-sora p-4 border-none text-mediumslateblue-100 no-underline rounded-3xs cursor-pointer text-xl">
              Learn more
            </a>
          </div>
        </div>
      </div>
    </Application>
  )
}

LandingPage.propTypes = propTypes
LandingPage.defaultProps = defaultProps

export default memo(LandingPage)
