import React, { memo } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import Application from '../Application'
import { NewPasswordForm } from 'forms'
import { LoginLinks } from 'components'

const propTypes = {
  resource: PropTypes.object,
  controllerName: PropTypes.string,
  isConfirmable: PropTypes.bool,
  submitUrl: PropTypes.string,
  authenticityToken: PropTypes.string.isRequired,
}

const defaultProps = {}

function NewPassword ({
  resource,
  controllerName,
  isConfirmable,
  submitUrl,
  authenticityToken,
}) {
  return (
    <Application>
      <h2>Forgot your password?</h2>
      <NewPasswordForm 
        user={resource}
        submitUrl={submitUrl}
        authenticityToken={authenticityToken}
      />
      <LoginLinks
        controllerName={controllerName} 
      />
    </Application>
  )
}

NewPassword.propTypes = exact(propTypes)
NewPassword.defaultProps = defaultProps

export default memo(NewPassword)