import { INCREMENT, DECREMENT, RESET } from "./actionTypes";

const initialState = {
  value: 0
};

const reducerKey = 'HelloWorld'

function reducer(state = initialState, action) {
  switch (action.type) {
    case INCREMENT: {
      const { value } = action.payload;
      return {
        ...state,
        value
      };
    }
    case DECREMENT: {
      const { value } = action.payload;
      return {
        ...state,
        value
      };
    }
    case RESET: {
      const { value } = action.payload;
      return {
        ...state,
        value
      }
    }
    default:
      return state;
  }
}

export {
  reducer,
  reducerKey
}
