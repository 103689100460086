import { INCREMENT, DECREMENT, RESET } from "./actionTypes";

export const increment = (value) => {
  return {
    type: INCREMENT,
    payload: {
      value: ++value
    }
  }
};

export const decrement = (value) => ({
  type: DECREMENT,
  payload: {
    value: --value
  }
});

export const reset = () => {
  return {
    type: RESET,
    payload: {
      value: 0
    }
  }
}
