import React, { memo } from 'react'
import store from 'store'
import { Provider } from 'react-redux'

const propTypes = {}

const defaultProps = {}

function Application ({
  children
}) {
  return (
    <Provider store={store}>
      { children }
      <footer id='footer' className="self-stretch bg-gray-200 flex flex-col items-start lg:items-center justify-start relative text-[1.25rem] text-light-text font-title py-[6.69rem] md:px-[0rem] px-[16px]">
        <div className="flex flex-col lg:flex-row items-start justify-start gap-[12.44rem]">
          <div className="flex flex-col items-start justify-start">
            <div className="flex md:flex-row items-start justify-start gap-[4.69rem]">
              <div className="flex flex-col items-start justify-start gap-[2.5rem]">
                <div className="flex flex-row items-center justify-start">
                  <div className="relative tracking-[0.1px] leading-[1.88rem] font-medium">
                    Mindful Conversion
                  </div>
                </div>
                <div className="w-[7.56rem] flex flex-col items-start justify-start gap-[1.19rem] text-[1rem]">
                  <div className="flex flex-row items-center justify-start">
                    <a href='/' className="text-light-text no-underline relative tracking-[0.2px] leading-[1.5rem] font-medium">
                      Home
                    </a>
                  </div>
                  <div className="flex flex-row items-center justify-start">
                    <a href='/features' className="text-light-text no-underline relative tracking-[0.2px] leading-[1.5rem] font-medium">
                      Features
                    </a>
                  </div>
                  <div className="flex flex-row items-center justify-start">
                    <a href='/pricing' className="text-light-text no-underline relative tracking-[0.2px] leading-[1.5rem] font-medium">
                      Pricing
                    </a>
                  </div>
                  <div className="flex flex-row items-center justify-start">
                    <a href='/contact' className="text-light-text no-underline relative tracking-[0.2px] leading-[1.5rem] font-medium">
                      Contact
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-[2.5rem]">
                <div className="flex flex-row items-center justify-start">
                  <div className="relative tracking-[0.1px] leading-[1.88rem] font-medium">
                    Legal
                  </div>
                </div>
                <div className="w-[7.56rem] flex flex-col items-start justify-start gap-[1.19rem] text-[1rem]">
                  <div className="flex flex-row items-center justify-start">
                    <div className="relative tracking-[0.2px] leading-[1.5rem] font-medium">
                      Privacy Policy
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-start">
                    <div className="relative tracking-[0.2px] leading-[1.5rem] font-medium">
                      Terms of Service
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start gap-[0.63rem] text-[1rem]">
            <div className="flex flex-row items-center justify-start">
              <img
                className="relative w-[2.88rem] h-[2.88rem] shrink-0"
                alt=""
                src="/images/bxbxmap.svg"
              />
              <div className="flex flex-row items-center justify-start py-[0rem] px-[0.5rem]">
                <div className="relative tracking-[0.2px] leading-[1.5rem] font-medium">
                  Maple Valley, Washington
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start">
              <div className="w-[2.88rem] h-[2.88rem] flex flex-row items-center justify-center gap-[0.5rem]">
                <img
                  className="relative w-[2rem] h-[2rem]"
                  alt=""
                  src="/images/mail.svg"
                />
              </div>
              <div className="flex flex-row items-center justify-start p-[0.63rem]">
                <a
                  className="relative [text-decoration:underline] tracking-[0.2px] leading-[1.5rem] font-medium text-[inherit]"
                  href="mailto:hello@keywordwatchdog.com"
                  target="_blank"
                >
                  hello@keywordwatchdog.com
                </a>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start py-[1.25rem] px-[0rem] gap-[1.5rem]">
              <img
                className="relative w-[2.56rem] h-[2.56rem] shrink-0 hidden"
                alt=""
                src="/images/antdesigntwitteroutlined.svg"
              />
              <a href='https://www.linkedin.com/company/keyword-watchdog/'>
                <img
                  className="relative w-[2.56rem] h-[2.56rem] shrink-0"
                  alt=""
                  src="/images/antdesignlinkedinfilled.svg"
                />
              </a>
            </div>
            <div className="flex flex-row items-center justify-start">
              <div className="flex flex-row items-center justify-start p-[0.63rem]">
                <span
                  className="relative font-medium text-light-text"
                >
                  © 2024 Keyword Watchdog. All Rights Reserved
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Provider>
  )
}

Application.propTypes = propTypes
Application.defaultProps = defaultProps

export default memo(Application)