import React, { memo, useState } from 'react'
// import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import KeywordWatchDogLogo from './KeywordWatchDogLogo'
import { capitalize, flip } from 'lodash'
import classNames from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
import SignUpButton from './SignUpButton'

const propTypes = {}

const defaultProps = {}

function MainMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuItems = ['features', 'pricing', 'contact']
  const navigationSlug = location.pathname.split('/')[1]

  function flipMenuIsOpen() {
    setIsMenuOpen(!isMenuOpen)
  }

  function closeMenu() {
    setIsMenuOpen(false)
  }

  return (
    <div className="px-[16px] w-full flex flex-row items-center pb-[1rem] lg:pt-[1rem] lg:px-[5rem] box-border gap-[1.5rem] justify-between lg:justify-start">
      <KeywordWatchDogLogo />
      {
        // Mobile menu
        isMenuOpen && (
          <div className='flex md:hidden w-full flex-col absolute px-[16px] left-0 top-0 bg-mediumslateblue-100 shadow-md h-[100vh] justify-between box-border z-10'>
            <div className='flex flex-col'>
              <div className='flex flex-row justify-between'>
                <KeywordWatchDogLogo white_variant />
                <img className='cursor-pointer' onClick={closeMenu} src='/images/close-icon.svg'/>
              </div>
              <div className='mt-10'></div>
              {menuItems.map((item, index) => {
                return (
                  <div className="flex flex-col items-left justify-start pb-8 gap-[0.5rem]">
                    <a href={item} className={classNames("no-underline text-light-text text-xl relative leading-[100%] font-medium")}>
                      {capitalize(item)}
                    </a>
                  </div>
                )
              })
              }
            </div>
            <div className='flex flex-col w-full pb-10'>
              <SignUpButton sandygold />
              <div className="rounded-3xs box-border h-[3rem] flex items-center justify-center py-[1rem] border-[2px] border-solid border-white w-full flex-col">
                <div className="flex flex-row items-center justify-center py-[0rem] w-full">
                  <div className="relative tracking-[0.5px] leading-[100%] font-semibold cursor-pointer">
                    Log In
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className='flex md:hidden'>
        <img className='cursor-pointer' onClick={flipMenuIsOpen} src='/images/hamburguer-menu-icon.svg' />
      </div>
      <div className="hidden md:flex flex-row items-center justify-center gap-[1rem] font-body-s flex-auto">
        {menuItems.map((item, index) => {
          return (
            <div className="flex flex-row items-center justify-start py-[0.75rem] px-[0.5rem] gap-[0.5rem]">
              <a href={item} className={classNames("no-underline text-light-text relative leading-[100%] font-medium", {
                'current_nav_item': item == navigationSlug
              })}>
                {capitalize(item)}
              </a>
              {item == 'features' && (
                <img
                  className="relative w-[1.5rem] h-[1.5rem] overflow-hidden shrink-0 hidden"
                  alt=""
                  src="/images/icon--jamicons--outline--logos--chevrondown.svg"
                />
              )}
            </div>
          )
        })
        }
      </div>
      <div className="hidden md:flex flex-row items-center justify-end gap-[1.5rem]">
        <div className="rounded-3xs box-border h-[3rem] flex flex-row items-center justify-center py-[1rem] px-[0.75rem] border-[2px] border-solid border-mediumslateblue-100">
          <div className="flex flex-row items-center justify-center py-[0rem] px-[1rem]">
            <div className="relative tracking-[0.5px] leading-[100%] font-semibold cursor-pointer">
              Log In
            </div>
          </div>
        </div>
        <SignUpButton />
      </div>
    </div>
  )
}

MainMenu.propTypes = exact(propTypes)
MainMenu.defaultProps = defaultProps

export default memo(MainMenu)