import reducerKey from "./reducerKey"

function select(state, key) {
  return state[reducerKey][key]
}

// const selectors = {
//   value: select('value')
// }

export {
  // selectors,
  select
}