import React, { memo, useState } from 'react'
import Application from './Application'
import HeroSection from '../components/HeroSection'
import SignUpButton from '../components/SignUpButton'
import Carousel from '../components/Carousel'
import TryItForFreeButton from '../components/TryItForFreeButton'
import classNames from 'classnames'

const propTypes = {}

const defaultProps = {}

function Features() {
  const features = [{
    title: 'On Demand Pricing',
    body: 'Pay for what you need, nothing more. Pay as little as $.04/mo. per keyword tracked.',
    image: '/images/placeholder-features.png',
    icon: '/images/on-demand-pricing-icon.svg'
  }, {
    title: 'Location-Specific Keyword Tracking',
    body: 'Track keywords for specific cities, states or countries. Worldwide.',
    image: '/images/placeholder-features.png',
    icon: '/images/location-specific-icon.svg'
  }, {
    title: 'Device Specific Keyword Tracking',
    body: 'Set your keyword tracking to desktop, mobile, or both!',
    image: '/images/placeholder-features.png',
    icon: '/images/device-specific-icon.svg'
  }, {
    title: 'Intelligent Keyword Suggestions*',
    body: 'Leverage our AI-injected keyword suggestion tools to help build out the right keywords to track for your business',
    image: '/images/placeholder-features.png',
    icon: '/images/intelligent-suggestions-icon.svg'
  }, {
    title: 'Keyword Groupings',
    body: 'Analyze performance for your entire portfolio of keywords, individual keywords, or specific groups of keywords using our intuitive hashtag system',
    image: '/images/placeholder-features.png',
    icon: '/images/keyword-grouping-icon.svg'
  }, {
    title: 'Any Keyword On Any Platform',
    body: 'Track Keywords Where Your Customers Are. Google, YouTube, Bing, Baidu. If there’s somewhere else you want to track, let us know!',
    image: '/images/placeholder-features.png',
    icon: '/images/keyword-platforms-icon.svg'
  }]
  const [selectedFeature, setSelectedFeature] = useState(features[0])
  const [displayedFeature, setDisplayedFeature] = useState(features[0])

  return (
    <Application>
      <HeroSection>
        <div className="w-full flex flex-col items-center justify-start py-[5rem] px-[16px] md:px-[5rem] box-border relative gap-[0.63rem] text-[2.63rem]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[1.38rem]">
            <div className='flex flex-col justify-center w-full items-center'>
              <div className="relative text-[3rem] tracking-[0.2px] font-semibold inline-block w-full md:w-[40.69rem] mb-6">
                <p className='text-center'>
                  Core Product Features
                </p>
              </div>
              <div className='flex w-full h-auto'>
                <div className='hidden md:flex w-full'>
                  <div className='flex flex-col w-1/3 h-auto bg-coolgray-10 rounded-tl-xl rounded-bl-xl justify-between'>
                    {
                      features.map((feature, index) => {
                        return (
                          <div className={classNames('py-6 px-9 flex justify-center flex-col bg-coolgray-10 text-coolgray-90 cursor-pointer', {
                            'bg-mediumslateblue-100 text-light-text': selectedFeature.title === feature.title,
                            'rounded-tl-xl': index == 0,
                            'rounded-bl-xl': index == features.length - 1,
                          })} onClick={() => {
                            setSelectedFeature(feature)
                          }}>
                            <h3 className='text-lg mb-0 mt-0'> {feature.title} </h3>
                            {
                              selectedFeature.title === feature.title && (
                                <>
                                  <p className='text-xs mb-0 mt-[1rem]'> {feature.body} </p>
                                </>
                              )
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                  <img className="flex w-2/3 h-auto rounded-tr-xl rounded-br-xl" src={selectedFeature.image} />
                </div>
                <div className='flex md:hidden w-full gap-2'>
                  <Carousel>
                    {features.map((feature, index) => {
                      return (
                        <div className='flex shrink-0 flex-col flex-wrap w-full h-[300px] ml-[2rem]'>
                          <div className="flex carousel-place-holder shadow-[0px_0px_34px_rgba(0,_0,_0,_0.05)] shrink-0 flex-row items-center justify-center h-full w-full flex-1 rounded-t-xl" />
                          <div className="flex flex-col items-center justify-center py-[16px] box-border h-auto bg-[#F2F4F8] rounded-3xs self-center w-11/12 -mt-8">
                            <h3 className='text-lg mb-0 mt-0 text-mediumslateblue-100'> {feature.title} </h3>
                            <p className='text-xs mb-0 mt-[1rem] text-gray-300 mx-4'> {feature.body} </p>
                          </div>
                        </div>
                      )
                    })}
                  </Carousel>
                </div>
              </div>
              <SignUpButton sandygold />
              <div className='flex flex-col w-full md:w-[543px] text-center text-lightdark'>
                <p className='font-medium text-xs'>
                  * = Coming Soon
                </p>
                <p className='font-medium text-xs mt-0'>
                  We prioritize features based on what customers need. Email us at features@keywordwatchdog.com if you want move something to the top of our roadmap.
                </p>
              </div>
            </div>
          </div>
        </div>
      </HeroSection>
      <div className="hidden md:flex self-stretch [background:linear-gradient(#fff,_#fff),_#f2f4f8] overflow-hidden flex-col items-center justify-start py-[5rem] px-[0rem] gap-[2.5rem] font-sora flex-wrap">
        <div className="flex flex-row flex-wrap items-center justify-start gap-[0.13rem] text-[2.25rem] text-midnightblue w-full md:px[4rem] md:px-[2rem] lg:px-0 box-border">
          <div className="flex flex-row items-start justify-center py-[0rem] lg:gap-[2rem] text-[1.5rem] text-gray-300 flex-wrap 2xl:px-32">
            {features.map((feature, index) => {
              return (
                <div className="flex flex-col items-start justify-start gap-[1.5rem] lg:py-[2rem] lg:px-[2.5rem] md:py-[0.5rem] md:px-[0.5rem] 2xl:flex-auto 2xl:w-1/4 w-1/4 md:w-1/5">
                  <div className="flex flex-col items-start justify-start gap-[0.5rem]">
                    <div className="flex flex-col items-start justify-start">
                      <img
                        className="relative"
                        alt=""
                        src={feature.icon}
                      />
                    </div>
                    <div className="relative leading-[110%] font-semibold mb-[1rem]">
                      {feature.title}
                    </div>
                    <div className="relative text-[1.25rem] tracking-[0.2px] leading-[1.88rem] font-body-s text-dimgray">
                      {feature.body}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className='flex flex-col lg:gap-[2rem] items-center w-full'>
            <div className="box-border flex flex-row items-center justify-center text-[1.25rem] text-gray-300 w-fit mt-8">
              <TryItForFreeButton />
            </div>
            <div className='flex flex-col w-[543px] text-center'>
              <p className='font-medium text-xs'>
                * = Coming Soon
              </p>
              <p className='font-medium text-xs mt-0'>
                We prioritize features based on what customers need. Email us at features@keywordwatchdog.com if you want move something to the top of our roadmap.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col md:hidden'>
        <div className="mt-10 w-full flex flex-col items-start justify-start gap-[3rem] text-center">
          <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem]">
            <div className="self-stretch relative text-[2rem] leading-[110%] font-semibold text-darkblue font-sora">
              Keyword Tracking
            </div>
            <span className='font-medium text-dimgray'> Accurate Google KW Tracking at the frequency you need at. </span>
          </div>
        </div>
        <div className='flex w-full flex-col wrap'>
          { features.map((feature, index) => {
            return (
              <div className='flex w-full px-[2rem] py-[3rem] rounded-xl bg-white justify-between items-center mb-4 box-border border-b-[1px] border-t-0 border-l-0 border-r-0 border-solid border-b-gray-500'>
                <div className="flex-1 overflow-hidden flex flex-col items-start justify-start gap-[1.5rem]">
                  <div className="flex flex-col items-start justify-start gap-[0.5rem]">
                    <div className="flex items-start justify-start max-w-[45px] w-auto gap-3">
                      <img
                        className="relative"
                        alt=""
                        src={feature.icon}
                        width={48}
                        height={48}
                      />
                      <p className="relative leading-[110%] font-semibold font-sora whitespace-nowrap">
                        {feature.title}
                      </p>
                    </div>
                    <p className={classNames("relative hidden text-[1.25rem] tracking-[0.2px] leading-[1.88rem] font-body-s text-dimgray", {
                      '!block': displayedFeature.title === feature.title
                    })}>
                      {feature.body}
                    </p>
                  </div>
                </div>
                <img className={classNames('h-4 w-7 self-center', {
                  'transform rotate-180': displayedFeature.title === feature.title
                })} onClick={() => {
                  if(!(displayedFeature.title === feature.title)) {
                    setDisplayedFeature(feature)
                  }
                }} src='/images/down-arrow.svg' />
              </div>
            )
          })}
        </div>
      </div>

      <div className='flex flex-col md:flex-row'>
        <div className='flex flex-1 px-[16px] md-[4.5rem] md:px-[3.5rem] py-[16px] md:py-[5rem] flex-col bg-mediumslateblue-100 md:w-1/2 box-border'>
          <div>
            <h3 className='text-light-text text-23xl font-sora'> Reporting like <br /> No Other</h3>
            <p className='font-medium text-light-text text-lg'> We know how important (and time consuming) reporting can be. So we try and take that out of your hands with branded reports, report scheduling, or share the live dashboard directly with clients, colleagues, or on LinkedIn </p>
            <div className='flex md:hidden w-full overflow-x-auto gap-2'>
              <Carousel>
                <div className='flex shrink-0 flex-col flex-wrap w-full'>
                  <div className="flex flex-col items-center justify-center py-[16px] h-auto bg-white rounded-3xs self-center w-[98%]">
                    <div className='flex flex-wrap p-[24px]'>
                      <div className='flex mr-[1.5rem] items-start w-full shrink-0 mb-[16px]'>
                        <img
                          className="relative"
                          alt=""
                          src="/images/real-time-reports-icon.svg"
                        />
                      </div>
                      <div className='flex flex-col'>
                        <h4 className='mt-0 mb-[8px] font-sora text-5xl'> Share Real-Time Reports </h4>
                        <span className='font-medium'> No more exporting PDFs. Share viewable links to any project you track with your clients, coworkers, or best friends. </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex shrink-0 flex-col flex-wrap w-full'>
                  <div className="flex flex-col items-center justify-center py-[16px] h-auto bg-white rounded-3xs self-center w-[98%]">
                    <div className='flex flex-wrap p-[24px]'>
                      <div className='flex mr-[1.5rem] items-start w-full shrink-0 mb-[16px]'>
                        <img
                          className="relative"
                          alt=""
                          src="/images/branded-reports-icon.svg"
                        />
                      </div>
                      <div className='flex flex-col'>
                        <h4 className='mt-0 mb-[8px] font-sora text-5xl'> Branded Reports </h4>
                        <span className='font-medium'> Client-Ready Keyword Tracking Reports with your logo, not ours. </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex shrink-0 flex-col flex-wrap w-full'>
                  <div className="flex flex-col items-center justify-center py-[16px] h-auto bg-white rounded-3xs self-center w-[98%]">
                    <div className='flex flex-wrap p-[24px]'>
                      <div className='flex mr-[1.5rem] items-start w-full shrink-0 mb-[16px]'>
                        <img
                          className="relative"
                          alt=""
                          src="/images/scheduled-reports-icon.svg"
                        />
                      </div>
                      <div className='flex flex-col'>
                        <h4 className='mt-0 mb-[8px] font-sora text-5xl'> Scheduled Reports* </h4>
                        <span className='font-medium'> Schedule your branded reports for when you need them. </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex shrink-0 flex-col flex-wrap w-full'>
                  <div className="flex flex-col items-center justify-center py-[16px] h-auto bg-white rounded-3xs self-center w-[98%]">
                    <div className='flex flex-wrap p-[24px]'>
                      <div className='flex mr-[1.5rem] items-start w-full shrink-0 mb-[16px]'>
                        <img
                          className="relative"
                          alt=""
                          src="/images/notifications.svg"
                        />
                      </div>
                      <div className='flex flex-col'>
                        <h4 className='mt-0 mb-[8px] font-sora text-5xl'> Intelligent Alerting* </h4>
                        <span className='font-medium'> Get alerts right to your inbox when we see meaningful changes in your keyword rankings. </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
            <div className='flex flex-col gap-[2rem] w-full items-start'>
              <div className="box-border flex flex-row items-center justify-center text-[1.25rem] text-gray-300 w-fit mt-8">
                <TryItForFreeButton />
              </div>
              <div className='flex flex-col w-full md:w-[543px] text-light-text'>
                <p className='font-medium text-xs'>
                  * = Coming Soon
                </p>
                <p className='font-medium text-xs mt-0'>
                  We prioritize features based on what customers need. Email us at features@keywordwatchdog.com if you want move something to the top of our roadmap.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Reporting like no other tablet and above */}
        <div className='md:flex hidden flex-1 flex-col gap-[2rem] lg:pl-[5rem] lg:pr-[7.5rem] md:px-[2rem] py-[5rem] bg-light-dimgray md:w-1/2'>
          <div className='flex'>
            <div className='flex mr-[1.5rem] items-start'>
              <img
                className="relative"
                alt=""
                src="/images/real-time-reports-icon.svg"
              />
            </div>
            <div className='flex flex-col'>
              <h4 className='mt-0 font-sora text-5xl'> Share Real-Time Reports </h4>
              <span className='font-medium'> No more exporting PDFs. Share viewable links to any project you track with your clients, coworkers, or best friends. </span>
            </div>
          </div>
          <div className='flex'>
            <div className='flex mr-[1.5rem] items-start'>
              <img
                className="relative"
                alt=""
                src="/images/branded-reports-icon.svg"
              />
            </div>
            <div className='flex flex-col'>
              <h4 className='mt-0 font-sora text-5xl'> Branded Reports </h4>
              <span className='font-medium'> Client-Ready Keyword Tracking Reports with your logo, not ours. </span>
            </div>
          </div>
          <div className='flex'>
            <div className='flex mr-[1.5rem] items-start'>
              <img
                className="relative"
                alt=""
                src="/images/scheduled-reports-icon.svg"
              />
            </div>
            <div className='flex flex-col'>
              <h4 className='mt-0 font-sora text-5xl'> Scheduled Reports* </h4>
              <span className='font-medium'> Schedule your branded reports for when you need them. </span>
            </div>
          </div>
          <div className='flex'>
            <div className='flex mr-[1.5rem] items-start'>
              <img
                className="relative"
                alt=""
                src="/images/blue-alerts-icon.svg"
              />
            </div>
            <div className='flex flex-col'>
              <h4 className='mt-0 font-sora text-5xl'> Intelligent Alerting* </h4>
              <span className='font-medium'> Get alerts right to your inbox when we see meaningful changes in your keyword rankings </span>
            </div>
          </div>
        </div>
      </div>

      <div className="self-stretch [background:linear-gradient(#fff,_#fff),_#f2f4f8] overflow-hidden flex flex-col items-center justify-start py-[5rem] px-[16px] md:px-[0rem] gap-[2rem] font-sora">
        <div className="w-full lg:w-[80rem] flex flex-col items-start justify-start gap-[3rem] text-center mb-[2.5rem]">
          <div className="self-stretch flex flex-col items-center justify-start gap-[0.5rem]">
            <div className="self-stretch relative text-[2rem] leading-[110%] font-semibold text-darkblue">
              Built for Enterprise.
            </div>
            <span className='font-medium text-dimgray'>
              We know how important (and time consuming) reporting can be. So we try and take that out of your hands with branded reports, report scheduling, or share the live dashboard directly with clients, colleagues, or on LinkedIn
            </span>
          </div>
        </div>
        <div className="flex flex-row flex-wrap items-center justify-start gap-[2rem] text-[2.25rem] text-midnightblue w-full">
          <div className="flex flex-row items-start justify-center py-[0rem] px-0 lg:px-[7.5rem] md:px-[4rem] gap-[2rem] text-[1.5rem] text-gray-300 flex-wrap lg:flex-nowrap">
            <div className="flex-1 basis-2/5 w-2/5 lg:basis-auto lg:w-auto flex flex-col items-start justify-start gap-[1.5rem]">
              <div className="flex flex-col items-start justify-start">
                <img
                  className="relative"
                  alt=""
                  src="/images/unlimited-users-icon.svg"
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[0.5rem]">
                <div className="relative leading-[110%] font-semibold mb-[1rem]">
                  Unlimited Users
                </div>
                <div className="relative text-[1.25rem] tracking-[0.2px] leading-[1.88rem] font-body-s text-dimgray">
                  Share your account with up to 250 users. If you need more, just ask!
                </div>
              </div>
            </div>
            <div className="flex-1 basis-2/5 w-2/5 lg:basis-auto lg:w-auto flex flex-col items-start justify-start gap-[1.5rem]">
              <div className="flex flex-col items-start justify-start">
                <img
                  className="relative"
                  alt=""
                  src="/images/api-access-icon.svg"
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[0.5rem]">
                <div className="relative leading-[110%] font-semibold mb-[1rem]">
                  API Access*
                </div>
                <div className="relative text-[1.25rem] tracking-[0.2px] leading-[1.88rem] font-body-s text-dimgray">
                  Access your ranking data how you want to access it. All through our powerful API.
                </div>
              </div>
            </div>
            <div className="flex-1 basis-2/5 w-2/5 lg:basis-auto lg:w-auto flex flex-col items-start justify-start gap-[1.5rem] text-gray-100">
              <div className="flex flex-col items-start justify-start">
                <img
                  className="relative"
                  alt=""
                  src="/images/secure-icon.svg"
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[0.5rem]">
                <div className="relative leading-[110%] font-semibold mb-[1rem]">
                  Secure & Compliant
                </div>
                <div className="relative text-[1.25rem] tracking-[0.2px] leading-[1.88rem] font-body-s text-dimgray">
                  We leverage Microsoft’s Azure Cloud to ensure your data is safe and secure.
                </div>
              </div>
            </div>
            <div className="flex-1 basis-2/5 w-2/5 lg:basis-auto lg:w-auto flex flex-col items-start justify-start gap-[1.5rem] text-gray-100">
              <div className="flex flex-col items-start justify-start">
                <img
                  className="relative"
                  alt=""
                  src="/images/fast-icon.svg"
                />
              </div>
              <div className="flex flex-col items-start justify-start gap-[0.5rem]">
                <div className="relative leading-[110%] font-semibold mb-[1rem]">
                  Blazing Fast
                </div>
                <div className="relative text-[1.25rem] tracking-[0.2px] leading-[1.88rem] font-body-s text-dimgray">
                  Speed matters. So we built our application to update in milliseconds.
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col py-[0rem] px-[16px] md:px-[7.5rem] gap-[2rem] items-start md:items-center w-full'>
            <div className="box-border flex flex-row items-center justify-center text-[1.25rem] text-gray-300 w-fit mt-8">
              <TryItForFreeButton />
            </div>
            <div className='flex flex-col md:w-auto w-full text-left md:text-center'>
              <p className='font-medium text-xs'>
                * = Coming Soon
              </p>
              <p className='font-medium text-xs mt-0 w-full md:w-auto'>
                We prioritize features based on what customers need. Email us at features@keywordwatchdog.com if you want move something to the top of our roadmap.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Application>
  )
}

Features.propTypes = propTypes
Features.defaultProps = defaultProps

export default memo(Features)