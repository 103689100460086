import { configureStore } from '@reduxjs/toolkit'
import { 
  reducer as HelloWorld, 
  reducerKey as HelloWorldReducerKey
} from './HelloWorld/reducer'

export default configureStore({
  reducer: {
    [HelloWorldReducerKey]: HelloWorld
  },
})