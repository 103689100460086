import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import classNames from 'classnames'

const propTypes = {
  black_variant: PropTypes.bool
}

const defaultProps = {
  black_variant: false,
  keywords: 1,
  setKeywords: () => { }
}

function PriceSlider({
  black_variant,
  keywords,
  setKeywords
}) {
  function handleSetKeywords(v) {
    if (v) {
      localStorage.setItem("keywordsSelected", v.toFixed(2).toString())
      setKeywords(v)
    }
  }

  return (
    <div className="flex flex-row items-center justify-center gap-[1.25rem] w-full mb-3">
      <b className={classNames("relative tracking-[0.2px] leading-[1.56rem]", {
        'text-black': black_variant
      })}>
        1 KW
      </b>
      <div id='desktop-price-slider-container' className='flex w-full'>
        <div className="group flex relative w-full cursor-pointer">
          <input type="range" min="1" max="2500" style={
            {
              '--val': keywords,
              '--min': 1,
              '--max': 2500
            }
          } value={keywords} onChange={(v) => {
            handleSetKeywords(parseInt(v.target.value))
          }} className="slider h-7 rounded-lg cursor-pointer" id="desktop-price-slider" />
          <span className="group-hover:opacity-100 transition-opacity bg-[#475AFF] px-3 py-1 text-sm text-white rounded-md absolute left-1/2 
        -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto z-10">
            {keywords}
          </span>
        </div>
      </div>
      <b className={classNames("relative tracking-[0.2px] leading-[1.56rem] whitespace-nowrap", {
        'text-black': black_variant
      })}>
        2,500 KW
      </b>
    </div>
  )
}

PriceSlider.propTypes = exact(propTypes)
PriceSlider.defaultProps = defaultProps

export default React.memo(PriceSlider)